<template>
  <section>
    <section class="nominee-card" :class="data.video_info.platform">
      <div class="thumbnail-wrapper" :class="data.video_info.platform" @click="thumbnailClick">
        <div class="thumbnail-layer">
          <img class="thumbnail" :class="data.video_info.platform" :src="data.video_info.thumbnail_url" />
          <div class="overlay" :class="data.video_info.platform"></div>
          <div class="play-button"></div>
        </div>
      </div>

      <div class="contents-info" :class="data.video_info.platform">
        <div class="contents-info-desc" :class="data.video_info.platform">
          {{ data.video_info.title }}
        </div>
        <div class="contents-info-spacing" />
        <div class="contents-info-bottom" :class="data.video_info.platform">
          <div class="contents-info-bottom-count">총 득표수: {{ count }}</div>
          <div>
            <VoteButton
              class="contents-info-bottom-vote"
              :class="data.video_info.platform"
              :id="data._id.$oid"
              @voted="onVoted"
            />
            <ShareButton class="contents-info-bottom-share" :class="data.video_info.platform" :id="data._id.$oid" />
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { mapMutations } from 'vuex'
import VoteButton from '@/components/VoteButton'
import ShareButton from '@/components/ShareButton'
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: { VoteButton, ShareButton },

  data() {
    return {
      count: 0,
    }
  },
  created() {
    this.count = this.data.vote_amount
  },
  methods: {
    ...mapMutations('media', ['setMediaType', 'setMediaLink']),
    onVoted(count) {
      this.count = count
    },

    thumbnailClick() {
      if (this.data.video_info.platform === 'etc') {
        window.open(this.data.content_url, '_blank')
      } else {
        this.setMediaType(this.data.video_info.platform)
        this.setMediaLink(this.data.video_info.embedded_url)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.nominee-card {
  display: flex;
  border: 1px solid $t-grey-300;
  border-radius: 5px;
  overflow: hidden;
  background: white;
  max-width: 400px;
  width: 350px;
  margin: 0 auto;
  flex-direction: column;

  &.tiktok {
    flex-direction: row;
  }

  @include tablet {
    border-radius: 8px;
    width: 350px;
    height: 370px;
  }

  @include desktop {
    width: auto;
    height: 384px;
  }
  .media {
    margin: 0 auto;
    width: 100%;
  }
  .thumbnail-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    cursor: pointer;
    overflow: hidden;

    &.tiktok {
      width: 55%;
    }
  }

  .contents-info {
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100%;
    &.tiktok {
      width: 45%;
      padding: 20px 20px 20px 10px;
    }
    &-desc {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &.tiktok {
        -webkit-line-clamp: 3;
        @include tablet {
          -webkit-line-clamp: 5;
        }
      }
    }
    &-spacing {
      flex-grow: 1;
    }
    &-bottom {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &.tiktok {
        flex-direction: column;
      }
      &-count {
        flex-grow: 1;
        margin-right: 10px;
        margin-top: 10px;
        @include tablet {
          font-size: 18px;
        }
      }
      &-vote {
        margin-right: 10px;
        margin-top: 10px;
        &.tiktok {
          width: 100%;
        }
        color: white;
        border: 1px solid $t-primary-500;
      }
      &-share {
        &.tiktok {
          width: 100%;
        }
      }
    }
  }
}
</style>
