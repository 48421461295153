<template>
  <section>
    <ul class="awards-nominees">
      <li v-for="data in nominees" :key="`${data._id.$oid}`">
        <AwardsNomineeCard :data="data" />
      </li>
    </ul>
  </section>
</template>

<script>
import AwardsNomineeCard from '@/components/AwardsNomineeCard.vue'
export default {
  components: {
    AwardsNomineeCard,
  },
  props: ['nominees'],
}
</script>

<style lang="scss" scoped>
.awards-nominees {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  align-items: stretch;
  padding: 15px;
  margin: auto;

  @include tablet {
    width: 740px;
    grid-template-columns: repeat(2, 1fr);
  }
  @include desktop {
    width: auto;
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
