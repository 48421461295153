<template>
  <div class="contents">
    <div class="banner">
      <img class="image mobile" src="@/assets/images/banner-mobile.png" />
      <img class="image not-mobile" src="@/assets/images/banner.png" />
    </div>
    <div class="articles">
      <h1 class="articles-title">게시물</h1>
      <div class="nav">
        <div class="item" @click.stop="itemClick('ranking')" :class="[currentNav === 'ranking' ? 'active' : '']">
          투표순
        </div>
        <div class="item ml10" @click.stop="itemClick('random')" :class="[currentNav === 'random' ? 'active' : '']">
          랜덤순
        </div>
        <a-button class="item ml10">
          <a href="#" @click.stop="fetch">
            <a-icon type="reload" />
            새로고침
          </a>
        </a-button>
      </div>
      <div class="t-body2 ml10 mt10">순위는 실시간으로 변경됩니다.</div>
    </div>
    <AwardsNominees class="mt20" :nominees="nominees" />
    <div v-if="nominees.length" class="refresh" @click.stop="fetch">
      <a-button class="refresh-button">
        <a-icon type="sync" />
        새로고침
      </a-button>
    </div>
  </div>
</template>

<script>
import AwardsNominees from '@/components/AwardsNominees.vue'
import * as API from '@/utils/api'
// import DummyJSON from '@/dummy/test.json'

export default {
  components: {
    AwardsNominees,
  },
  data() {
    return {
      nominees: [],
      currentNav: 'ranking',
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.nominees = []
      this.$showLoading()
      try {
        const requestApi = this.currentNav === 'ranking' ? API.getPostByVotes : API.getPosts
        const data = await requestApi()
        // this.nominees = data.slice(0, 33)
        this.nominees = data
      } catch (err) {
        this.$showErrorMessage(err)
      }
      this.$hideLoading()
    },
    itemClick(item) {
      if (this.currentNav === item) {
        return
      }

      this.currentNav = item
      this.fetch()
    },
  },
}
</script>
<style lang="scss" scoped>
.articles {
  margin: auto;
  margin-top: 30px;

  &-title {
    font-weight: bold;
    font-size: 24px;
    @include tablet {
      font-weight: bold;
      font-size: 28px;
    }
  }
  width: 350px;
  @include tablet {
    width: 700px;
    margin-top: 60px;
  }
  @include desktop {
    width: 1160px;
  }
}
.banner {
  display: flex;
  justify-content: center;
  margin: auto;

  @include tablet {
    margin-top: 40px;
  }
  .image {
    @include tablet {
      width: 740px;
    }
    @include desktop {
      width: 100%;
    }
  }
  .mobile {
    display: none;
    @include mobile {
      display: block;
      width: 350px;
    }
  }
  .not-mobile {
    display: block;
    @include mobile {
      display: none;
    }
  }
}

.nav {
  display: flex;

  .active {
    color: $t-grey-700;
    color: $t-primary-500;
    text-decoration-line: underline;
  }

  .item {
    cursor: pointer;
    padding: 5px 10px;
  }
}
.refresh {
  display: flex;
  justify-content: center;

  .refresh-button {
    max-width: 1024px;
    height: 60px;
    margin: 30px 0;
    width: 350px;
    @include tablet {
      width: 710px;
    }
  }
}
</style>
